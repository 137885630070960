<template>
  <div class="background">
    <b-container fluid>
      <b-row>
        <b-col md="6" class="d-flex justify-content-center">
          <img src="@/assets/ichLogo.png" class="ichLogo">
        </b-col>
        <b-col md="6" class="text-center">
          <h4>เข้าสู่ระบบ</h4>
          <br>
          <b-col md="6" offset-md="3" class="formLogin">
            <b-form-group label="อีเมล:" label-for="email" :horizontal="true">
              <b-form-input id="email" v-model="email" placeholder="อีเมล" required>
              </b-form-input>
            </b-form-group>
            <b-form-group label="รหัสผ่าน:" label-for="email" :horizontal="true">
              <b-form-input id="password" type="password" v-model="password"
              placeholder="รหัสผ่าน" required @keyup.enter="loginUser()">
              </b-form-input>
            </b-form-group>
            <p v-if="statusLogin=='fail'" class="errText">{{loginFail}}</p>
          </b-col>
          <b-button class="loginBtn" @click="loginUser()">เข้าสู่ระบบ</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      loginFail: 'อีเมลหรือรหัสผ่านผิด',
    };
  },
  computed: {
    ...mapGetters('User', ['statusLogin']),
  },
  methods: {
    ...mapActions('User', ['login']),
    async loginUser() {
      const loginResult = await this.login({ email: this.email, password: this.password });
      console.log(loginResult);
      if (loginResult === 'success') {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="css" scoped>
  .row {
    height: 100vh;
  }
  .col-md-6 {
    margin: auto;
  }
  .ichLogo {
    width: 500px;
  }
  .formLogin {
    text-align: left;
  }
  .background {
    background-color: #FAFAFC;
    border-bottom-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  .errText {
    color: red;
  }
  .loginBtn {
    background: transparent linear-gradient(105deg,#002dff,#ff2aa1) 0 0 no-repeat padding-box;
    border-radius: 15px;
  }
  @media screen and (max-width: 800px) {
    .ichLogo {
      width: 350px;
    }
  }
</style>
